.t-text-wrapper {
  width: 151px;
  height: 26px;
  left: 457px;
  top: 314px;
  margin-bottom: 46px;
  font-style: normal;
  font-weight: 400;
  font-size: 21.5172px;
  line-height: 26px;
  text-align: center;
  color: #CFCFCF;
}

.p-span {
  font-weight: 800;
}

.c-form {
  width: 100%;
}

.login-wrapper {
  width: 100%;
}