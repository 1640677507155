.c-button {
  background-color: #fe8a00;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  height: 47px;
  font-weight: 700;
  width: 100%;
  left: 457px;
  top: 470px;
  border-radius: 4px;
  border: none;
  color: #1E2733;
}